import styled from "styled-components"

export const KontaktFormHolder = styled.div`
background:#f7f7f7;
width:100%;

display:flex;
justify-content:center;
align-items:center;
flex-direction:column;

 .secret {
 display:none;
 }
.mina-uppgifter { 
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    margin-bottom:70px;
    width: 80%;
    margin: 0 auto;
    padding-top: 70px;
    @media screen and (min-width: 768px) {
        flex-direction:row;
        justify-content:space-around;
    }

    .row-del {
        width:90%;
        @media screen and (min-width: 768px) {
        width:40%;

        }
        input {
            height:40px;
            border:1px solid #f2f2f2;
            border-radius:50px;
            width:90%;
            margin-bottom:15px;
            padding-left:15px;
        }
        label {
            font-family: 'Nunito', sans-serif;

        }
        textarea {
            width:70%;
            height:152px;
            border:none;
            padding:35px;
            border-radius:50px;

        }
    }
}

.form-end {
    padding:35px;
    text-align:center;

    
    .gdpr {
        width: 70%;
        margin: 0 auto;
        padding-top: 35px;
        font-family: 'Nunito', sans-serif;
        font-size:.8rem;
        padding-bottom:35px;

        input {
            margin-right:10px;
            
        }
    }
    .button {
            width: 100%;
            max-width:400px;
            height: 50px;
            border-radius: 50px;
            text-align: center;
            border:1px solid #ccc; 
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: .8rem;
            transition:.3s;
            margin-top:5px;
            margin-bottom:25px;
            background:#ccaf89;
    
            :hover {
                background:transparent;
                border:1px solid #ccaf89;
                color:black;
                cursor:pointer;
            }
    }
}


`
