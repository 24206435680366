import styled from "styled-components"


export const SuccessHolder = styled.div`
color:Black;
width:60%;
height:50%;
padding: 35px;
border:2px solid green;
margin:70px auto;


a {
    border: 1px solid black;
    background: white;
    padding: 10px 35px;
    border-radius: 50px;
    color:black;

    &:hover {
        color:white;
        background: black;
        
    }
}
`