import styled from "styled-components"

export const OffertHolder = styled.div`
background:white;
width:100%;
margin:0 auto;
background:#fff;
padding:35px 0;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column; 

form {
    width: 100%;
}

h2 {
    font-size:1.1rem;
    width:100%;
    text-align:center;
    position:relative;
    @media screen and (min-width: 768px) {
        font-size:1.3rem;
    }

    &::after {
        content:"";
        background:linear-gradient(to right, rgba(3, 7, 29, 0.95), rgba(9, 64, 110, 0.9));
        height:2px;
        width:50%;
        left:25%;
        position:absolute;
        bottom:-5px;

    }
}

.kund-info {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 769px) {
        flex-direction: row;    
    }

    h3 {
        font-size:1.1rem;
        width:100%;
        text-align:center;
        position:relative;
        @media screen and (min-width: 768px) {
            font-size:1.1rem;
        }
    }

    .mina-uppgifter {
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        margin-bottom:15px;
        padding:5px;
        width: 100%;

        @media screen and (min-width: 769px) {
            flex-direction:column;
            width: 50%;
            justify-content: flex-start;
            padding:35px;
        }

        .row-del {
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            width: 100%;
            margin-top:35px;

            @media screen and (min-width: 768px) {
                justify-content: space-around;

            }

            label {
                width: 30%;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size:.5rem;
                color:rgba(0,0,0,.7);
                font-family: 'Montserrat', sans-serif;

                @media screen and (min-width: 768px) {
                font-size:.65rem;
                width: 20%;
                }

                input {
                    height:20px;
                    border:1px solid #f2f2f2;
                    border-radius:0px;
                    width:100%;
                    margin-bottom:15px;
                    padding-left:15px;
                    margin-top: 10px;
                    position: relative;
                    left: -5px;

                    
                    
                }
            }
            .nono {
                color:black;
                font-size:0;
                width: auto;
                input {
                    width: 125px;
                    padding-left:0;
                    height: 30px;
                    border-radius: 50px;
                    text-align: center;
                    border:1px solid #ccc;
                    letter-spacing: 1px;
                    text-transform: capitalize;
                    font-size: .8rem;
                    transition:.3s;
                    margin-bottom:35px;
    
                    &::placeholder {
                        text-transform: uppercase;
                        font-size: .65rem;
                    }
    
                    @media screen and (min-width: 768px) {
                        margin-bottom:0;
                    }
    
                    &:focus {
                        outline:none;
                        border: 1px solid #ccaf89;
                    }
                }
            }
            
            
            
        }
        .del-tva {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            margin-top:35px;

            @media screen and (min-width: 768px) {
                flex-direction: row;
                justify-content:space-around;

            }
        }
    }
    .tjanst-val {
        background:#f1f1f1;

        h3 {
            font-size:1.1rem;
            width:100%;
            text-align:center;
            position:relative;
            @media screen and (min-width: 768px) {
                font-size:1.1rem;
            }
        }

        .gdpr {
            width: 80%;
            margin: 0 auto;
            padding-top: 30px;
            font-family: 'Nunito', sans-serif;
            font-size:.6rem;
            padding-bottom:30px;

            @media screen and (min-width: 769px) {
                width: 100%;

            }
    
            input {
                margin-right:10px;
                
            }
        }
        .button {
            width: 100%;
            height: 50px;
            border-radius: 50px;
            text-align: center;
            border:1px solid #ccc; 
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: .8rem;
            transition:.3s;
            margin-top:5px;
            margin-bottom:25px;
            background:#ccaf89;

            @media screen and (min-width: 768px) {
            width: 50%;
            margin: 0 auto;
            }
    
            :hover {
                background:transparent;
                border:1px solid #ccaf89;
                color:black;
                cursor:pointer;
            }
        }
        .del-tva {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            margin-top:35px;

            @media screen and (min-width: 768px) {
                flex-direction: row;
                justify-content:space-around;

            }
        }
    }
    .kund-uppgifter {
        background:#f9f9f9;

        .nono {
            font-size:0;
       
        textarea {
            height: 120px;
            width: 90%;
            position: relative;
            left: 5%;
            border-radius: 50px;
            text-align: center;
            padding: 5px;
            text-transform: capatilaze;
            border:1px solid #ccc;
            letter-spacing: 1px;
            font-size: .8rem;
            font-family: 'Montserrat', sans-serif;
            margin-top:25px;

            @media screen and (min-width: 769px) {
                width: 100%;
                left:0;

            }

            &::placeholder {
                text-transform: uppercase;
            }
    
            &:focus {
                outline:none;
                border: 1px solid #ccaf89;
            }
        }
    }
    }
}


.row-check {
    display: flex;
    flex-direction: row;
    margin-bottom:35px;
    padding-left:15px;

    h3 {
        margin-top:0;
    }

    div {
        display: flex;
        flex-direction: column;
        label {
            height:50px;
            font-family: 'Nunito', sans-serif;
    
            input {
                width: 25px !important;
                position: relative;
                top: 12px;
    
            }
        }
    }
    
}







.flytt {
    display:flex;
    flex-direction:column;

    @media screen and (min-width: 768px) {
        flex-direction:row;
    }

    .form-end-desk {
        padding: 0;
        padding-top: 35px;
        text-align:center;
        justify-content:flex-end;
        align-items:center;
        width:100%;
        display:flex;
        flex-direction: column;
        order: 3;


        @media screen and (min-width: 768px) {
            padding:35px;
            text-align:center;
            width:30%;
            order:2;

        }
    
        textarea {
            height: 150px;
            width: 80%;
            border-radius: 50px;
            text-align: center;
            padding: 5px;
            text-transform: capatilaze;
            border:1px solid #ccc;
            letter-spacing: 1px;
            font-size: .8rem;
            font-family: 'Montserrat', sans-serif;
            margin-top:25px;

            @media screen and (min-width: 768px) {
                width: 100%;

            }

            &::placeholder {
                text-transform: uppercase;
            }
    
            &:focus {
                outline:none;
                border: 1px solid #ccaf89;
            }
        }
        
        .gdpr {
            width: 80%;
            margin: 0 auto;
            padding-top: 15px;
            font-family: 'Nunito', sans-serif;
            font-size:.6rem;
            padding-bottom:15px;

            @media screen and (min-width: 768px) {
                width: 100%;

            }
    
            input {
                margin-right:10px;
                
            }
        }
        .button {
            width: 200px;
            height: 50px;
            border-radius: 50px;
            text-align: center;
            border:1px solid #ccc;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: .8rem;
            transition:.3s;
            margin-top:5px;
            margin-bottom:25px;
            background:#ccaf89;
    
            :hover {
                background:transparent;
                border:1px solid #ccaf89;
                color:black;
                cursor:pointer;
            }
        }
    }
}

.flytt div {
    width:50%;
    text-align: left;
    padding-left: 15px;
}

h3 {
    margin:0;
}


`