import React from "react"
import { graphql, Link } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"
import OffertForm from "components/OffertForm"
import KontaktForm from "components/KontaktForm"
import Success from "components/Success"
//Styles
import { HeroSides, Logo } from "components/HeroTwoSides/HeroTwoSides.styles"

  //Hooks 
import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'

const Page = ({ data }) => {
  const page = data.markdownRemark 
  const siteConfig = useSiteConfigQuery()

  return (
    <Layout className="page-layout">

    {page.frontmatter.slug !== "success" &&  
     <>
      
      <SEO title={page.frontmatter.seotitle} description={page.frontmatter.seodescription} />
      <HeroSides >    
        <div className="left" >
        <Logo src={siteConfig.logo.publicURL} width="100" height="100" alt="Städfirmor Borås" />

        </div>
        <div className="right">
            <h1><strong>{page.frontmatter.titleh1}?</strong> Då har
                du kommit till rätt  <strong>hemsida.</strong> </h1>
            <div className="knappar"> 
                <Link to="/" title="Städfirmor Borås" className="animate__animated animate__fadeIn animate__delay-2s">
                    Startsidan
                </Link>     
            </div>
            <p className="nationell">
            Kontakta oss genom att fylla in formulären nedan så återkommer vi.
            </p>
        </div>
      </HeroSides>
      <main className="subpage-content">
        <div className="subpage-text">
          <h2>{page.frontmatter.titleh2}</h2>
          <div dangerouslySetInnerHTML={{ __html: page.html }} /> 
        </div>
      </main>

      <OffertForm />
      </>}

        {page.frontmatter.slug === "kontakt" && 
          <>
           <KontaktForm />      
         </> }

         {page.frontmatter.slug === "success" && 
          <>
           <Success />      
         </> } 
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html 
      frontmatter { 
        titleh1
        titleh2
        date
        slug 
        seotitle
        seodescription
      }
    }
  }
`
